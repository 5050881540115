import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from "../layouts";
import SEO from "../layouts/seo";
import {
  Container,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    marginTop: '160px',
    marginBottom: '60px',
  },
  blogPost: {
    fontSize: '18px',
    fontFamily: 'Roboto',
    lineHeight: '38px',

    "& .video-container": {
      position: 'relative',
      paddingBottom: '56.25%', /* 16:9 */
      height: 0,

      "& iframe": {
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
      }
    },
  },
}));

const BlogTemplate = ({ data }) => {
  const classes = useStyles();
  
  if (!data.markdownRemark) {
    return <></>
  }

  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark

  return (
    <Layout>
      <SEO title={frontmatter.title} />
      <Container maxWidth="lg" className={classes.root}>
        {/* <Img fluid={data.strapiBlog.image.childImageSharp.fluid}/>
        <h2>{data.strapiBlog.title}</h2>
        <p>by <Link to={`/authors/User_${data.strapiBlog.author.id}`}>{data.strapiBlog.author.username}</Link></p> */}

        <div className={classes.blogPost}>
          <Img fluid={frontmatter.featuredImage.childImageSharp.fluid} />
          <h1>{frontmatter.title}</h1>
          {/* <h2>{frontmatter.date}</h2> */}
          <div
            className="blog-post-content"
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </Container>
    </Layout>
  )
}

export default BlogTemplate;

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        path
        title
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1300, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
